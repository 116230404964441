import { Switch, Route } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
import Login from "../Screens/Login/Login";
import Error from "../Screens/Error/Error404";

export const LoginRoute = (
  <Switch>
        <Route exact path="/" render={() => <Login />} />
        <Route render={() => <Error />} />
  </Switch>
);
