import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Screens/Home/Home";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import CreateRTC from "../Screens/CreateRTC/CreateRTC";
import ViewRTC from "../Screens/ViewRTC/ViewRTC";
import UploadRTCExcelData from "../Screens/CreateRTC/UploadRTCExcelData";
import ChangePassword from "../Screens/ChangePassword/ChangePassword";
import GenerateRTC from "../Screens/GenerateRTC/GenerateRTC";
import Login from "../Screens/Login/Login";
import Report from "../Screens/Report/Report";
import Error from "../../src/Screens/Error/Error404";

export const MasterRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/userManagement" render={() => <UserManagement />} />
    <Route exact path="/createRTC" render={() => <CreateRTC />} />
        <Route exact path="/viewRTC" render={() => <ViewRTC />} />
        <Route exact path="/ViewRTC" render={() => <ViewRTC />} />
    <Route
      exact
      path="/UploadRTCExcelData"
      render={() => <UploadRTCExcelData />}
    />
    {/* <Route exact path="/changePassword" render={() => <ChangePassword />} /> */}
    <Route exact path="/generateRTC" render={() => <GenerateRTC />} />
    <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/Report" render={() => <Report />} />
        <Route render={() => <Error />} />
  </Switch>
);

export const SpecialUserRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route
      exact
      path="/UploadRTCExcelData"
      render={() => <UploadRTCExcelData />}
    />
    <Route exact path="/changePassword" render={() => <ChangePassword />} />
    <Route exact path="/generateRTC" render={() => <GenerateRTC />} />
        <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/viewRTC" render={() => <ViewRTC />} />
        <Route exact path="/ViewRTC" render={() => <ViewRTC />} />
        <Route render={() => <Error />} />
  </Switch>
);

export const CustomerRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Route exact path="/" render={() => <GenerateRTC />} />
        <Route exact path="/Report" render={() => <GenerateRTC />} />
        <Route render={() => <Error />} />
  </Switch>
);

export const SiemensInternalRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Route exact path="/" render={() => <GenerateRTC />} />
        <Route exact path="/Report" render={() => <GenerateRTC />} />
        <Route render={() => <Error />} />
  </Switch>
);

export const ExternalRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Route exact path="/" render={() => <GenerateRTC />} />
        <Route exact path="/Report" render={() => <GenerateRTC />} />
        <Route render={() => <Error />} />
  </Switch>
);
