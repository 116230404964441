// JavaScript source code
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import { Button } from "@material-ui/core";

export default function Report() {
  const api = axios.create({
    baseURL: "api/RTCAPI",
  });

  //This function is for download pdf
  //const data = [
  //    {
  //        machineNo: "65279062"

  //    }]
  const MachineNo = 65273236;
  function downloadPDFIconClick() {
    //Const MachineNo = 65279062;

    api
      .post("/getSummaryReportDetailsPDF/" + MachineNo, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/pdf",
        });
        FileSaver.saveAs(blob, "SummaryReport.pdf");
        console.log("Download Excel Clicked");
      });

    console.log("Download PDF Clicked");
  }
  return (
    <Button
      className="pt-button--primary bulk-upload-submit-btn"
      type="button"
      onClick={downloadPDFIconClick}
      fullWidth
    >
      Download
    </Button>
  );
}
