import {
    authUserAction,
    storeUserDetailsAction,
    saveAccessTokenAction,
    errorMessageAction,
} from "../Redux/Actions/actions";

const baseApiURL = window.location.origin;

export function ResetUserPassword(siteToken, data) {

    const options = {

        method: "post",

        headers: {

            Accept: "application/json, text/plain, */*",

            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,

        },

        body: JSON.stringify(data),

    };

    return fetch(

        baseApiURL + "/Login/ChangePassword",

        options

    )

        .then((response) => response.json())

        .then((data) => {

            return data.result;

        })

        .catch((error) => {

            console.log("Error : ", error);

        });
}


    export function forgotUserPassword(siteToken, data) {
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken,
            },
            body: JSON.stringify(data),
        };
        return fetch(
            baseApiURL + "/Login/ForgotPassword",
            options
        )
            .then((response) => response.json())
            .then((data) => {
                return data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

