import { createTheme } from "@material-ui/core/styles";
import "../assets/colors.css";

export const lightTheme = createTheme({
  overrides: {
    //Buttons
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: `none`,
        /*Hero Button*/
        "&.Mui-disabled": {
          cursor: "no-drop",
          pointerEvents: "auto",
        },
        "&.pt-button--hero": {
          background: `var(--btn-hero-bg-gradient)`,
          color: `var(--btn-hero-text-color)`,
          padding: `10px`,
          fontSize: `1rem`,

          "&.pt-button--hero:hover": {
            background: `var(--btn-hero-bg-hover)`,
          },

          "&.pt-button--hero:focus": {
            background: `var(--btn-hero-bg-active)`,
          },
        },
        /*Primary Button*/
        "&.pt-button--primary": {
          background: `var(--btn-primary-bg-color)`,
          color: `var(--btn-primary-text-color)`,
          padding: `10px`,
          border: `1px solid var(--btn-primary-bg-color)`,

          "&.pt-button--primary:hover": {
            background: `var(--btn-primary-bg-hover)`,
          },

          "&.pt-button--primary:focus": {
            background: `var(--btn-primary-bg-active)`,
          },
        },
        "&.pr-button--primary": {
          background: `var(--btn-primary-bg-color)`,
          color: `var(--btn-primary-text-color)`,
          padding: `5px`,
          margin: `0px`,

          "&.pt-button--primary:hover": {
            background: `var(--btn-primary-bg-hover)`,
          },

          "&.pt-button--primary:focus": {
            background: `var(--btn-primary-bg-active)`,
          },
        },
        /*Secondary Button*/
        "&.pt-button--secondary": {
          background: `var(--btn-secondary-bg-color)`,
          color: `var(--btn-secondary-text-color)`,
          border: `1px solid var(--blue-green)`,
          padding: `10px`,
          transition: `0.5s`,

          "&.pt-button--secondary:hover": {
            background: `var(--btn-secondary-bg-color-hover)`,
            color: `var(--btn-secondary-text-color-hover)`,
            border: `1px solid #005159`,
          },

          "&.pt-button--secondary:focus": {
            background: `var(--btn-secondary-bg-color-active)`,
            color: `var(--btn-secondary-text-color-active)`,
          },
        },

        /*Reject Button*/
        "&.pt-button--reject": {
          background: `var(--btn-delete-bg-color)`,
          color: `var(--btn-delete-text-color)`,
          border: `1px solid var(--light-red)`,
          padding: `10px`,
          transition: `0.5s`,

          "&.pt-button--reject:hover": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
            border: `1px solid var(--light-red)`,
          },

          "&.pt-button--reject:focus": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
          },
        },

        //Button in PO
        "&.pr-button--secondary": {
          background: `var(--btn-secondary-bg-color)`,
          color: `var(--btn-secondary-text-color)`,
          border: `1px solid var(--blue-green)`,
          padding: `7px`,
          margin: `9px`,
          //display: "flex",
          //justifyContent: "center",

          transition: `0.5s`,

          "&.pr-button--secondary:hover": {
            background: `var(--btn-secondary-bg-color-hover)`,
            color: `var(--btn-secondary-text-color-hover)`,
            border: `1px solid #005159`,
          },

          "&.pr-button--secondary:focus": {
            background: `var(--btn-secondary-bg-color-active)`,
            color: `var(--btn-secondary-text-color-active)`,
          },
        },
        /*tertiary Button*/
        "&.pt-button--tertiary": {
          background: `var(--btn-tertiary-bg-color)`,
          color: `var(--btn-tertiary-text-color)`,
          padding: `10px`,

          "&.pt-button--tertiary:hover": {
            background: `var(--btn-tertiary-bg-color-hover)`,
            color: `var(--btn-tertiary-text-color-hover)`,
          },

          "&.pt-button--tertiary:focus": {
            background: `var(--btn-tertiary-bg-color-active)`,
            color: `var(--btn-tertiary-text-color-active)`,
          },
        },
        /*Delete Button*/
        "&.pt-button--delete": {
          background: `var(--btn-delete-bg-color)`,
          color: `var(--btn-delete-text-color)`,
          padding: `5px`,
          transition: `0.25s`,
          minWidth: `auto`,
          "&.pt-button--delete:hover": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
          },
        },
        //Button in PO
        "&.pr-button--delete": {
          background: `var(--btn-delete-bg-color)`,
          color: `var(--btn-delete-text-color)`,
          padding: `7px`,
          margin: `9px`,
          // display: "flex",
          // justifyContent: "center",
          transition: `0.25s`,
          //  minWidth: `auto`,
          "&.pr-button--delete:hover": {
            background: `var(--btn-delete-bg-color-hover)`,
            color: `var(--btn-delete-text-color-hover)`,
          },
        },

        /*Quick Links Button*/
        "&.pt-button--quickLinks": {
          backgroundColor: `var(--btn-quick-links-bg-color)`,
          color: `var(--btn-quick-links-text-color)`,
          transition: `0.25s`,
          "&.pt-button--quickLinks:hover": {
            background: `var(--btn-quick-links-bg-color-hover)`,
            color: `var(--btn-quick-links-text-color-hover)`,
          },
        },

        /*Feedback Button*/
        "&.pt-button--feedback": {
          background: `var(--feedback-btn-bg-color)`,
          color: `var(--feedback-btn-text-color)`,
          "&.pt-button--feedback:hover": {
            background: `var(--feedback-btn-bg-color-hover)`,
          },
        },
        "&.pt-button--feedback-submit": {
          width: `25%`,
        },
        "&.pt-button--view-account": {
          width: `75%`,
          fontSize: `14px`,
          padding: `10px`,
        },
        /*Preview Button*/
        "&.pt-button--preview-button": {
          marginLeft: `20px`,
          padding: `5px 10px`,
          fontSize: `12px`,
        },
        "&.pt-button--online-pact-navbtn": {
          borderBottom: `2px solid var(--transparent)`,
          padding: `10px 20px`,
          "&&&&:hover": {
            borderBottom: `2px solid var(--light-green--2)`,
            backgroundColor: "#00CCCC33",
          },
          "&&&&:focus": {
            background: `var(--light-green)`,
          },
        },
        "&.myid-login-btn": {
          padding: "8px 40px",
        },
        "&.supplier-login-btn": {
          padding: "8px 40px",
        },
        "&.bulk-upload-submit-btn": {
          marginTop: "4px",
        },
        "&.pt-button--save": {
          marginTop: "20px",
        },
        "&.generate-qrcode-btn": {
          marginBottom: "20px",
        },
        //multiline field
      },
    },
    //Label and Input
    MuiFormLabel: {
      root: {
        color: `var(--dark-blue)`,
        //paddingLeft: `0.4rem`,
        "&$focused": {
          color: `var(--dark-blue)`,
        },
      },
    },

    MuiFilledInput: {
      root: {
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,
        backgroundColor: `var(--input-bg-color)`,
        // background: `var--input-bg-color)`,
        fontSize: "14px",
        "&.Mui-focused": {
          backgroundColor: `var(--input-bg-color)`,
        },

        "&$disabled": {
          color: `var(--disable-text-color)`,
          cursor: "no-drop",
        },
        "&&&&:hover": {
          backgroundColor: `var(--input-bg-color-hover)`,
        },
        "&.Mui-error": {
          //  backgroundColor: `var(--error-red-bg-color)`,
          borderBottom: `1px solid var(--error-red--color)`,
          color: `var(--error-red--color)`,

          //  "&&&&:hover": {
          //      backgroundColor: `var(--error-red-bg-color)`,
          //  },
          "& underline": {
            "&&&&:before": {
              borderBottom: `1px solid var(--error-red--color)`,
            },
            "&&&&:after": {
              borderBottom: `2px solid var(--error-red--color)`,
            },
          },
        },
      },
      underline: {
        "&&&&:before": {
          borderBottom: `1px solid #00B3B3`,
        },
        "&&&&:after": {
          borderBottom: `2px solid #00B3B3`,
        },
      },
      input: {
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,
        //  backgroundColor: `var(--input-bg-color)`,
        "&&&&:hover": {
          backgroundColor: `#D1FFF2`,
        },
        "&#supplierEmail": {
          textTransform: "lowercase",
        },
      },
    },
    MuiInputLabel: {
      filled: {
        fontSize: `14px`,
      },
    },

    // MuiInput: {
    //   fullWidth: {
    //     width: `20%`,

    //   },
    // },

    //Related Tag - Chipss
    MuiChip: {
      deleteIcon: {
        padding: `0px `,
        width: `0.6em`,
      },
      outlined: {
        border: `1px solid var(--chips-bg-color)`,
        color: `var(--chips-text-color)`,
        backgroundColor: `var(--white)`,
        "&&&&:hover": {
          backgroundColor: `var(--chips-bg-color-hover)`,
          color: `var(--chips-text-color-hover)`,
        },
      },
    },
    //Tabs
    MuiTabs: {
      root: {
        backgroundColor: `var(--white)`,
      },
      flexContainer: {
        justifyContent: "space-between",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: `var(--white)`,
        color: `var(--dark-blue) !important`,
        textTransform: "none",
      },
      wrapper: {
        fontSize: `0.9rem`,
      },
    },
    PrivateTabIndicator: {
      root: {
        borderBottom: `4px solid var(--light-green)`,
      },
      colorPrimary: {
        backgroundColor: `transparent`,
      },
      colorSecondary: {
        backgroundColor: `var(--light-green)`,
      },
    },
    //Dialog
    MuiDialog: {
      //root: {
      //    "&.feedback-dialog-block": {
      //        "&.MuiDialog-paper": {
      //            maxWidth: `10px`
      //        }
      //    }
      //},
      paper: {
        background: `var(--white)`,
        minWidth: `350px`,
      },
    },
    MuiDialogTitle: {
      root: {
        color: `var(--dark-blue)`,
        //textAlign: "center",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: `center`,
        padding: `10px`,
      },
    },
    //Svg Icon

    MuiSvgIcon: {
      root: {
        color: `var(--dark-blue)`,
        //background: `var(--bg-gradient)`,
        background: `var(--transparent)`,
        padding: `10px`,
        borderRadius: `50%`,
        fontSize: `2.5rem`,

        "&.header-search": {
          background: `var(--transparent)`,
          zIndex: `1`,
          position: `relative`,
          left: `-15px`,
        },
        "&.sidedrawer-icon": {
          background: `var(--transparent)`,
        },
        "&.MuiSelect-iconFilled": {
          background: `var(--transparent)`,
          //top: `10px`,
          top: `5px`,
        },
        "&.MuiSelect-icon": {
          right: `0px`,
          top: `auto`,
        },
      },

      fontSizeLarge: {
        fontSize: "3.5rem !important",
      },
      fontSizeSmall: {
        fontSize: "2.5rem",
      },
    },

    //Select Dropdown
    MuiSelect: {
      icon: {
        top: `-5px`,
      },
      select: {
        color: `var(--select-text-color)`,
        borderTopLeftRadius: `0px`,
        borderTopRightRadius: `0px`,

        "&:focus": {
          backgroundColor: `var(--transparent)`,
          color: `var(--select-active-text-color)`,
          borderTopLeftRadius: `0px`,
          borderTopRightRadius: `0px`,
        },
      },
      filled: {
        backgroundColor: `var(--select-bg-color) `,
      },
    },
    MuiList: {
      root: {
        color: `var(--select-list-text-color)`,
      },
      padding: {
        paddingTop: `0`,
        paddingBottom: `0`,
      },
    },
    MuiListItem: {
      root: {
        //margin: `5px 0`,
        //borderTop: `1px solid var(--blue-green)`,
        //borderBottom: `1px solid var(--blue-green)`,
        "&.Mui-selected": {
          backgroundColor: `var(--select-list-item-bg-color)`,
        },
        "&&&&:hover": {
          backgroundColor: `var(--select-list-item-bg-color-hover)`,
          transform: `scale(0.99)`,
          transition: `0.5s`,
        },
      },
    },
    MuiListItemText: {
      root: {
        //borderBottom: `2px solid var(--blue-green)`,
        //padding: `5px 0`,
        color: `var(--white)`,
        fontWeight: `900 !important`,
      },
    },
    //Switch
    MuiSwitch: {
      track: {
        backgroundColor: `#00FFB9`,
      },
    },
    //Accordion
    MuiAccordion: {
      root: {
        marginBottom: `10px`,
        boxShadow: `0 2px 10px 0 #CCCCCC80`,
        border: `none`,
        width: `100%`,
        "&&&&:before": {
          backgroundColor: `var(--transparent)`,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        /*backgroundColor: `var(--accordian-active-bg-color)`/,*/
        color: `var(--master-blue)`,
        fontFamily: `SiemensSans-Bold`,
        transition: `0.5s`,
        "&.Mui-expanded": {
          backgroundColor: `var(--accordian-heading-bg-color-hover)`,
        },
        "&&&&:hover": {
          backgroundColor: `var(--accordian-heading-bg-color-hover) !important`,
        },
        "& .MuiSvgIcon-root": {
          background: `var(--transparent)`,
        },
      },
      content: {
        margin: 0,

        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "SiemensSlab-Black",
      },
      h2: {
        fontFamily: "SiemensSlab-Black",
      },
      h3: {
        fontFamily: "SiemensSlab-Black",
      },
      h4: {
        fontFamily: "SiemensSlab-Black",
      },
      h5: {
        fontFamily: "SiemensSlab-Black",
      },
      h6: {
        fontFamily: "SiemensSlab-Black",
      },
    },
    MuiDialogContentText: {
      root: {
        "&.feedback-dialog": {
          fontFamily: `SiemensSans-Bold`,
          color: `var(--dark-blue)`,
        },
      },
    },
    // MuiDialogContent: {
    //   root: {
    //     padding: `16px 24px`,
    //   },
    // },
    MuiRating: {
      icon: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--light-grey)`,
        },
      },
      iconHover: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--petrol-green)`,
        },
      },
      iconFilled: {
        "& svg": {
          background: `var(--transparent)`,
          color: `var(--blue-green)`,
        },
      },
    },
    MuiPopover: {
      root: {
        "&.my-account-menu": {
          position: `relative`,
          top: `20px`,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: `var(--blue-green)`,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: `var(--side-drawer-bg-color)`,
      },
    },
    MuiIconButton: {
      root: {
        "&&&&:hover": {
          backgroundColor: `var(--transparent)`,
          //padding: "12px",
        },
        "&.password-adornment": {
          padding: `0px`,
          "&&&&:hover": {
            backgroundColor: `var(--transparent)`,
            padding: "0px",
          },
        },
      },
      sizeSmall: {
        padding: "12px",
      },
    },
    MuiTooltip: {
      popper: {
        "& div": {
          background: `var(--green-type-4)`,
        },
      },
    },
    MuiRadio: {
      root: {
        "& span div svg": {
          background: `var(--transparent)`,
          color: `var(--petrol-green)`,
        },
      },
    },
    MuiStepper: {
      root: {
        overflow: "auto",
        paddingLeft: 0,
      },
    },
    MuiStepLabel: {
      iconContainer: {
        "&.MuiStepLabel-alternativeLabel": {
          position: "relative",
          bottom: "6px",
        },
      },
    },
    MuiStepIcon: {
      root: {
        background: "var(--transparent)",
        "&.MuiStepIcon-root": {
          color: "var(--light-blue)",
          padding: "4px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        "& .MuiPaper-root": {
          width: "100%",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        "& .MuiSvgIcon-root": {
          background: "none",
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: `55px`,
        "& .MuiSvgIcon-root": {
          background: "none",
        },
        // selectIcon: {
        //   top: `4px`,
        // },
      },
    },
    MuiTableSortLabel: {
      icon: {
        background: "none",
      },
    },
    MuiTableRow: {
      root: {
        "&.bg-amber": {
          backgroundColor: "var(--yellow)",
        },
      },
    },
    MuiCircularProgress: {
      svg: {
        color: "var(--circular-progress-color)",
      },
    },

    MuiPickersDatePickerRoot: {
      toolbar: {
        background: `var(--blue-green)`,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: `var(--blue-green)`,
        "&&&&:hover": {
          backgroundColor: `var(--blue-green)`,
        },
      },
    },

    MuiFormHelperText: {
      root: {
        position: "absolute",
        marginTop: "-16px",
        marginLeft: "-2px",
        fontSize: "10px",
        "&#supplierEmail-helper-text": {
          position: "relative",
          top: "12px",
        },
        "&#supplierPassword-helper-text": {
          position: "relative",
          top: "12px",
        },
        color: `var(--error-red-text-color)`,
      },
      contained: {
        marginLeft: `0px`,
        marginRight: `0px`,
      },
    },
    MuiTable: {
      root: {
        marginBottom: `0`,
      },
    },

    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid #00B3B3`,
        },
        "&$focused:after": {
          borderBottomColor: `#00B3B3`,
        },
        "&$error:after": {
          borderBottomColor: `#00B3B3`,
        },
        "&:before": {
          borderBottom: `1px solid #00B3B3`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid #00B3B3`,
        },
        "&$disabled:before": {
          borderBottom: `1px dotted #00B3B3`,
        },
      },
    },
    MuiFormControl: {
      root: {
        "&.mb-1": {
          marginBottom: `1rem`,
        },
        "&.mt-1": {
          marginTop: `1rem`,
        },
        "&.ml-1": {
          marginLeft: `1rem`,
        },
      },
    },
    MuiGrid: {
      container: {
        "&.change-password-container": {
          boxShadow: `5px 4px 7px 5px #CCC`,
          borderRadius: `10px`,
          marginTop: `5rem`,
        },
      },
      item: {
        "&.ml-1": {
          marginLeft: `1rem`,
        },
        "&.mb-1": {
          marginBottom: `1rem`,
        },
      },
    },

    MuiAutocomplete: {
      root: {
        backgroundColor: "var(--input-bg-color)",
      },
      endAdornment: {
        top: `-10px`,
      },
      clearIndicator: {
        "&&&&:hover": {
          padding: `4px`,
        },
      },
      noOptions: {
        backgroundColor: "var(--select-bg-color-hover)",
      },
      loading: {
        backgroundColor: "var(--select-bg-color-hover)",
      },
    },
    MuiTableCell: {
      root: {
        padding: `8px`,
        fontSize: `12px !important`,
        border: `1px solid #ccc`,
      },
    },
  },
});

// export const tableHeaderStyle = {
//   backgroundColor: "var(--light-sand)",
//   color: "var(--dark-blue)",
// };
export const tableHeaderStyle = {
  backgroundColor: "var(--light-sand)",
  //backgroundColor: "var(--landing-page-card-bg-color)",
  //color: "var(--dark-blue)",
};

export const tableOptions = {
  //  headerStyle: tableHeaderStyle,
  showTitle: false,
  draggable: false,
  pageSize: 5,
  pageSizeOptions: [5, 10, 25, 50],
  paginationPosition: "bottom",
  //paging: false,
  maxBodyHeight: "600px",
};
export const tableOptionsUser = {
  headerStyle: tableHeaderStyle,
  showTitle: false,
  draggable: false,
  pageSize: 3,
  // search: true,
  // add: true,
  pageSizeOptions: [5, 10, 25, 50],
  paginationPosition: "bottom",
  //paging: false,
  maxBodyHeight: "600px",
};
export const tableOptionsExport = {
  headerStyle: tableHeaderStyle,
  showTitle: false,
  draggable: false,
  pageSize: 3,
  exportButton: true,
  pageSizeOptions: [5, 10, 25, 50],
  paginationPosition: "bottom",
  //paging: false,
  maxBodyHeight: "600px",
};

// export const tableCellStyleMLFB = {
//   minWidth: "9rem",
//   maxWidth: "9rem",
// };

// export const tableHeaderStyleMLFB = {
//   textAlign: "center"
// };
// export const tableHeaderStyleSupplier = {
//   textAlign: "center",
//   paddingRight: "0px",
// }
// export const tableCellStyleLeadTime = {
//   minWidth: "7rem",
//   maxWidth: "7rem",
// }
